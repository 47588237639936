/********IMPORT DEPENDANCIES*********/
import { db } from "./firebase.js";
import { collection, getDocs, query, where, doc, getDoc, orderBy, 
  limit  } from "firebase/firestore";
import '../scss/home.scss'

/********SWIPER SLIDESHOW*********/
import Swiper from 'swiper';
import {Navigation,Pagination,Autoplay} from 'swiper/modules';

document.addEventListener("DOMContentLoaded", function () {

  loadStoreImages();
  loadArtists();

  // Function to load and update store images
  async function loadStoreImages() {
    const shopCards = document.querySelectorAll('.shop-search-card');

    shopCards.forEach(async (card) => {
        const shop_uid = card.getAttribute('data-shop-id');

        if (!shop_uid) return; // Skip if no UID is available

        try {
            const shopDocRef = doc(db, "shops_geo", shop_uid);
            const docSnapshot = await getDoc(shopDocRef);

            if (docSnapshot.exists()) {
                const shopData = docSnapshot.data();
                const shopProperties = shopData.shop_properties;

                if (shopProperties && Array.isArray(shopProperties.shop_images) && shopProperties.shop_images.length > 0) {
                    const firstImage = shopProperties.shop_images[0];

                    // Update the card's image with the Firebase image
                    const imgElement = card.querySelector('.default-image');
                    if (imgElement) {
                        imgElement.src = firstImage;
                    }
                } else {
                    // console.log(`No images for shop: ${shop_uid}`);
                }
            } else {
                // console.log(`No document found for shop: ${shop_uid}`);
            }
        } catch (error) {
            console.error(`Error fetching data for shop: ${shop_uid}`, error);
        }
    });
}


/********SHOP SEARCH TAB *********/
const countryInputShop = document.getElementById('countryShopInput');
const stateShopSelect = document.getElementById('stateShopInput');
const stateShopTextInput = document.getElementById('stateShopTextInput');
const cityShopInput = document.getElementById('cityShopInput');
const slideOutBoxModalPop = document.getElementById('slideOutBoxModalPop');
const slideOutNo = document.getElementById('slideOutNo');
const slideOutRemind = document.getElementById('slideOutRemind');

countryInputShop.addEventListener('change', function() {
  const selectedCountryShop = this.value;

  if (selectedCountryShop) {
    cityShopInput.disabled = false;
    if (selectedCountryShop === 'US') {
        stateShopSelect.classList.remove('d-none');
        stateShopSelect.disabled = false;
        stateShopTextInput.classList.add('d-none');
        stateShopTextInput.disabled = true;

        // Load states for the United States
        stateShopSelect.innerHTML = `
          <option value="" selected disabled>Select state / province</option>
<option value="AL">Alabama</option>
<option value="AK">Alaska</option>
<option value="AZ">Arizona</option>
<option value="AR">Arkansas</option>
<option value="CA">California</option>
<option value="CO">Colorado</option>
<option value="CT">Connecticut</option>
<option value="DE">Delaware</option>
<option value="DC">District of Columbia</option>
<option value="FL">Florida</option>
<option value="GA">Georgia</option>
<option value="HI">Hawaii</option>
<option value="ID">Idaho</option>
<option value="IL">Illinois</option>
<option value="IN">Indiana</option>
<option value="IA">Iowa</option>
<option value="KS">Kansas</option>
<option value="KY">Kentucky</option>
<option value="LA">Louisiana</option>
<option value="ME">Maine</option>
<option value="MD">Maryland</option>
<option value="MA">Massachusetts</option>
<option value="MI">Michigan</option>
<option value="MN">Minnesota</option>
<option value="MS">Mississippi</option>
<option value="MO">Missouri</option>
<option value="MT">Montana</option>
<option value="NE">Nebraska</option>
<option value="NV">Nevada</option>
<option value="NH">New Hampshire</option>
<option value="NJ">New Jersey</option>
<option value="NM">New Mexico</option>
<option value="NY">New York</option>
<option value="NC">North Carolina</option>
<option value="ND">North Dakota</option>
<option value="OH">Ohio</option>
<option value="OK">Oklahoma</option>
<option value="OR">Oregon</option>
<option value="PA">Pennsylvania</option>
<option value="RI">Rhode Island</option>
<option value="SC">South Carolina</option>
<option value="SD">South Dakota</option>
<option value="TN">Tennessee</option>
<option value="TX">Texas</option>
<option value="UT">Utah</option>
<option value="VT">Vermont</option>
<option value="VA">Virginia</option>
<option value="WA">Washington</option>
<option value="WV">West Virginia</option>
<option value="WI">Wisconsin</option>
<option value="WY">Wyoming</option>`;
    } else {
        stateShopSelect.classList.add('d-none');
        stateShopSelect.disabled = true;
        stateShopTextInput.classList.remove('d-none');
        stateShopTextInput.disabled = false;
    }
  }
});



/********ARTIST SEARCH TAB *********/
    const countryInput = document.getElementById('countryInput');
    const stateSelect = document.getElementById('stateInput');
    const stateTextInput = document.getElementById('stateTextInput');
    const cityInput = document.getElementById('cityInput');
    const cityList = document.getElementById('cityList');
    let cities = [];
  
    countryInput.addEventListener('change', function() {
      const selectedCountry = this.value;
  
      if (selectedCountry) {
        cityInput.disabled = false;
        
        if (selectedCountry === 'US') {
          stateSelect.classList.remove('d-none');
          stateSelect.disabled = false;
          stateTextInput.classList.add('d-none');
          stateTextInput.disabled = true;
  
          // Load states for the United States
          stateSelect.innerHTML = `
            <option value="" selected disabled>Select state / province</option>
<option value="AL">Alabama</option>
<option value="AK">Alaska</option>
<option value="AZ">Arizona</option>
<option value="AR">Arkansas</option>
<option value="CA">California</option>
<option value="CO">Colorado</option>
<option value="CT">Connecticut</option>
<option value="DE">Delaware</option>
<option value="DC">District of Columbia</option>
<option value="FL">Florida</option>
<option value="GA">Georgia</option>
<option value="HI">Hawaii</option>
<option value="ID">Idaho</option>
<option value="IL">Illinois</option>
<option value="IN">Indiana</option>
<option value="IA">Iowa</option>
<option value="KS">Kansas</option>
<option value="KY">Kentucky</option>
<option value="LA">Louisiana</option>
<option value="ME">Maine</option>
<option value="MD">Maryland</option>
<option value="MA">Massachusetts</option>
<option value="MI">Michigan</option>
<option value="MN">Minnesota</option>
<option value="MS">Mississippi</option>
<option value="MO">Missouri</option>
<option value="MT">Montana</option>
<option value="NE">Nebraska</option>
<option value="NV">Nevada</option>
<option value="NH">New Hampshire</option>
<option value="NJ">New Jersey</option>
<option value="NM">New Mexico</option>
<option value="NY">New York</option>
<option value="NC">North Carolina</option>
<option value="ND">North Dakota</option>
<option value="OH">Ohio</option>
<option value="OK">Oklahoma</option>
<option value="OR">Oregon</option>
<option value="PA">Pennsylvania</option>
<option value="RI">Rhode Island</option>
<option value="SC">South Carolina</option>
<option value="SD">South Dakota</option>
<option value="TN">Tennessee</option>
<option value="TX">Texas</option>
<option value="UT">Utah</option>
<option value="VT">Vermont</option>
<option value="VA">Virginia</option>
<option value="WA">Washington</option>
<option value="WV">West Virginia</option>
<option value="WI">Wisconsin</option>
<option value="WY">Wyoming</option>

          `;
        } else {
          stateSelect.classList.add('d-none');
          stateSelect.disabled = true;
          stateTextInput.classList.remove('d-none');
          stateTextInput.disabled = false;
        }
      }
    });

/********SLIDEOUT BOX*********/
    $(function () {
        $(window).on('scroll', function() {
          if ($(window).scrollTop() > 50) {
            $("#slidebox").animate({ "right": "0px" }, 500);
          } else {
            $("#slidebox").stop(true).animate({ "right": "-330px" }, 500);
          }
        });
      });
      checkSlideCookie();

      if (slideOutBoxModalPop) {
        slideOutBoxModalPop.addEventListener('click', function() {
        trackSlideOutGeneric();
      });
    }

      if (slideOutNo) {
        slideOutNo.addEventListener('click', function() {
        trackNoThanks();
      });
    }

      if (slideOutRemind) {
        slideOutRemind.addEventListener('click', function() {
        trackRemindMe();
      });
    }

      function checkSlideCookie() {
        var slideBoxAlert = getSlideCookie("slideBox");
        if (slideBoxAlert != "" && slideBoxAlert != null) {
          $("#slideboxWrap").css({ display: "none" });
        } else {
          $("#slideboxWrap").css({ display: "block" });
        }
      }
      
      function getSlideCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
      
      function setSlideCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }
      function setSlideCookieResults(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }
      
      function trackSlideOutGeneric() {
        $("#slideboxWrap").css({ display: "none" });
        showArtistPage();
        gtag("event", "Artist Profile Submit - Slide Out Box", {
          event_category: "Slide Out Box",
          event_label: "Slide Out Box Btn Click",
        });
        setSlideCookieResults("slideBox", "true", 2);
      }
      
      function trackNoThanks() {
        setSlideCookie("slideBox", "true", 24);
        $("#slideboxWrap").css({ display: "none" });
        gtag("event", "No Thanks - Slide Out Box", {
          event_category: "Slide Out Box",
          event_label: "Slide Out Box No Thanks Clicked",
        });
      }
      function trackRemindMe() {
        setSlideCookie("slideBox", "true", 1);
        $("#slideboxWrap").css({ display: "none" });
        gtag("event", "Remind Me - Slide Out Box", {
          event_category: "Slide Out Box",
          event_label: "Slide Out Box Remind Me Clicked",
        });
      }
      
      function showArtistPage() {
        location = "/artist-submit/";
      }
});

// =====================================================
    //      Detail slider
    // =====================================================

    var detailSlider = new Swiper(".detail-slider", {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        breakpoints: {
            991: {
                slidesPerView: 4,
            },
            565: {
                slidesPerView: 3,
            },
        },

        // If we need pagination
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },

        // Navigation arrows
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // =====================================================
    //      Init swipers automatically
    // =====================================================

    $(".swiper-init").each(function () {
        var slider = $(this),
            configuration = JSON.parse($(this).attr("data-swiper"));

        new Swiper(slider, configuration);
    });

    var homeSlider = new Swiper(".home-slider", {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        speed: 1500,
        parallax: true,
        /*
        autoplay: {
            delay: 5000,
        },
        */
        // If we need pagination
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: "#homeNext",
            prevEl: "#homePrev",
        },
    });

    // =====================================================
    //      Items slider
    // =====================================================

    var itemsSliderFull = new Swiper(".items-slider-full", {
        slidesPerView: 6,
        spaceBetween: 20,
        loop: true,
        roundLengths: true,
        breakpoints: {
            1600: {
                slidesPerView: 5,
            },
            1400: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 3,
            },
            991: {
                slidesPerView: 3,
            },
            565: {
                slidesPerView: 3,
            },
            400: {
                slidesPerView: 2,
            },
            0: {
                slidesPerView: 1,
            },
        },

        // If we need pagination
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
      },
        modules: [Navigation, Pagination],
    });


    var artistSliderFull = new Swiper(".artist-slider-full", {
      slidesPerView: 2,
      spaceBetween: 20,
      loop: false,
      roundLengths: true,
      breakpoints: {
          1600: { slidesPerView: 2 },
          1400: { slidesPerView: 2 },
          1200: { slidesPerView: 2 },
          991: { slidesPerView: 2 },
          565: { slidesPerView: 2 },
          400: { slidesPerView: 2 },
          0: { slidesPerView: 1 },
      },
      pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets: true,
      },
      modules: [Pagination],
  });

  async function loadArtists() {
    try {
        const cachedData = await fetch('/utilities/cache_recent_artists.php');
        const data = await cachedData.json();

        if (Array.isArray(data) && data.length > 0) {
            displayArtists(data);
        } else {

            const artistsGeoRef = collection(db, "artists_geo");
            // const artistQuery = query(
            //     artistsGeoRef,
            //     where("artist_properties.active", "==", true),
            //     orderBy("artist_properties.created", "desc"),
            //     limit(10)
            // );
            const artistQuery = query(
              artistsGeoRef,
              where("artist_properties.active", "==", true),
              orderBy("artist_properties.created", "desc"),
              limit(10) // Limit results to 10
            );

            const querySnapshot = await getDocs(artistQuery);
            const artists = querySnapshot.docs.map(doc => doc.data());

            cacheArtistsData(artists);
            displayArtists(artists);
        }
    } catch (error) {
        console.error("Error loading artists:", error);
    }
}

async function cacheArtistsData(artists) {
    try {
        await fetch('/utilities/cache_recent_artists.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ artists }),
        });
    } catch (error) {
        console.error("Error caching artist data:", error);
    }
}

function displayArtists(artists) {
  if (!Array.isArray(artists) || artists.length === 0) {
    console.error("Invalid artist data:", artists);
    return;
  }

  const swiperWrapper = document.querySelector(".artist-slider-full .swiper-wrapper");

  artists.forEach((artist) => {
    const { artist_properties } = artist;
    const {
      artist_name,
      artist_username,
      profile_img,
      artist_photos = [],
      books_open,
      tattoo_types,
      instagram,
      website,
      facebook,
      city,
      state,
    } = artist_properties;

    // Fallback: Use profile image if available, otherwise try the first image in artist_photos
    const profileImage =
      profile_img ||
      (artist_photos.length > 0 ? artist_photos[0] : '/assets/images/default_profile.png');

    const firstThreeArtistTypes = tattoo_types.slice(0, 3).join(", ");

    const socialLinks = `
      ${instagram ? `<span class="me-1 text-primary"><i class="fa-brands fa-instagram"></i></span>` : ''}
      ${facebook ? `<span class="me-1 text-primary"><i class="fa-brands fa-facebook"></i></span>` : ''}
      ${website ? `<span class="me-1 text-primary"><i class="fa-solid fa-link"></i></span>` : ''}
    `;

    const booksOpenBadge = books_open
      ? `<span class="badge rounded-pill me-2 p-2 badge-success-light my-1"><i class="fa-regular fa-calendar-check"></i> BOOKS OPEN</span>`
      : `<span class="badge rounded-pill me-2 p-2 badge-info-light my-1"><i class="fa-regular fa-calendar-xmark"></i> BOOKS CLOSED</span>`;

    const slide = document.createElement("div");
    slide.classList.add("swiper-slide", "h-auto", "px-2");

    slide.innerHTML = `
      <a id="recent-artist-card" href="/${artist_username}" class="artist-card w-100 d-flex flex-column flex-md-row align-items-start">
        <div class="card border-0 shadow w-100">
          <div class="card-img-left">
            <img class="img-fluid artist-profile-image" src="${profileImage}" alt="${artist_name}" />
          </div>
          <div class="py-3 px-3 py-sm-1 px-sm-1 card-body d-flex flex-column justify-content-start">
            <h4 class="card-title mb-1">${artist_name}</h4>
            <p class="text-muted mb-1 text-sm"><i class="fa-solid fa-location-dot"></i> Located in ${city}, ${state}</p>
            <div class="d-flex">
              ${booksOpenBadge}
            </div>
            <p class="text-secondary text-sm mb-0"><i class="fa-solid fa-palette"></i> Specializing in ${firstThreeArtistTypes}, and more</p>
            <div id="social_links" class="d-flex mb-1">
              ${socialLinks}
            </div>
            <div class="d-flex w-100 justify-content-center mt-2">
              <button class="btn btn-primary">VIEW ARTIST</button>
            </div>
          </div>
        </div>
      </a>
    `;

    swiperWrapper.appendChild(slide);
  });

  artistSliderFull.update(); // Update Swiper instance
}